// import { IoKey } from "react-icons/io5";
// import { HiDevicePhoneMobile } from "react-icons/hi2";
import React, { useState } from 'react'
import Dropdown  from './Dropdown'
import Button from './Buttons'
// import { FaUser } from "react-icons/fa";
// import Routers from "../config/router/Routers";
import { Link } from "react-router-dom";
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
// import Home from "./Home";


function Nav({images}) {
  // console.log(images);
  const [toggle, setToggle] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0);


   const animateContent = () => {
     gsap.from(".logo",{
      y: -10,
      opacity:0,
      duration:1,
    })
    gsap.from("ul li",{
      y: -10,
      opacity:0,
      duration:1,
      stagger:.08

    })
  
    
  };
  
  

  let openMenu = ()=>{
    setToggle(!toggle)
  }

 const onChange = (current) => {
    setCurrentSlide(current);
    animateContent();
  };

  useGSAP(animateContent);
  return (
    <div  className='  text-sm w-full  h-18 bg-zinc-50  '>
      <nav class="bg-zinc-950 text-white   dark:bg-gray-900 dark:border-gray-700">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
       
        <span class=" logo self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Real estate</span>
    </a>
    <button onClick={openMenu}  type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-multi-level" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class={`${toggle ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-multi-level">

     <ul className="flex flex-col font-medium p-4 items-center md:text-md md:p-0 mt-4 border rounded-lg   md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
  <li className="underline text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[5.5rem] md:bg-transparent">
    <Link onClick={onChange} to={"/"}>
      <Button   label={"Home"} />
    </Link>
  </li>
  <li className="text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[5.5rem] md:bg-transparent mt-1">
    <Link to={"aboutUs"}>
      <Button label={"About Us"} />
    </Link>
  </li>
  <li className="text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[5.5rem] md:bg-transparent mt-1">
    <Link to={'projects'}>
      <Dropdown label={"Projects"} />
    </Link>
  </li>
  <li className="text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[5.5rem] md:bg-transparent mt-1">
    <Link to={'service'}>
      <Dropdown label={"Services"} />
    </Link>
  </li>
  <li className="text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[6rem] md:bg-transparent mt-1">
    <Link to={'contactUs'}>
      <Button label={"Contact Us"} />
    </Link>
  </li>
  <li className="text-center rounded p-2 text-xl text-black w-full bg-slate-100 md:text-base md:text-white md:w-[5.5rem] md:bg-transparent mt-1">
    <Link to={"login"} className="flex items-center justify-center gap-1">
      {/* <FaUser /> */}
      <span>Login</span>
    </Link>
  </li>
</ul>

    </div>
  </div>
</nav>

    

        {/* // <div className="items h-full uppercase bg-gray-100 gap-10 items-center justify-around  flex  w-full">
        //         <div className="logo">Logo</div>
        //     <ul className=' gap-8  font-semibold items-center  flex'>
              
        //     </ul>
        //     <button  className=' h-full flex items-center justify-around p-6 w-48 border-l-4 border-zinc-950 '><IoKey />Member's Login </button>
        // </div>

        // <div className="welcome uppercase bg-black text-white">
        //   <h1 className="text-[8vh] font-semibold font-san text-center p-5">welcome on our Team </h1>
          
        
        // </div> */}

    </div>
  )
}

export default Nav