import React, { useState } from 'react';
import { Carousel } from 'antd';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
// import c4 from '../assets/carousel 4.avif';
import c1 from '../assets/c1.jpeg';
import c2 from '../assets/c2.jpeg';
import c3 from '../assets/c3.jpeg';
import{Row,Col}from 'antd'
// import HomeCard from './HomeCards'

const contentStyle = {
  margin: 0,
  height: '100vh',
  width: '100%',
  object: 'cover',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const carouselData = [
  {
    img: c1,
    title: 'Builty Innovative & Industrial Solution',
    description: 'Builty is a construction and architecture environment most responsible.',
  },
  {
    img: c2,
    title: <><p className='text-white'>Build Your Future <br /> with Builty</p></>,
    description:  <><p className='text-white'>We provide sustainable and efficient building solutions.</p></>,
    
  },
  {
    img: c3,
    title: (
      <>
      <div className='text-white'>
        Create the <br />
        Building <br />
        YOU Want <br />
        Here
        </div>
      </>
    ),
    description: (
      <p className='text-white'>Our designs focus on functionality and aesthetics.</p>
    ),
  },
  
];

function Landing() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const animateContent = () => {
    gsap.from('.content', {
      y: 100,
      duration: 1,

    });
    
  };

  const onChange = (current) => {
    setCurrentSlide(current);
    animateContent();
  };

  useGSAP(animateContent);

  return (
    <div>
      <Carousel arrows afterChange={onChange} infinite={true}>
        {carouselData.map((item, index) => (
          <div key={index} className='relative'>
            <img src={item.img} alt="" style={contentStyle} />
            <div className="content absolute top-20 left-5">
              <h1 className='text-7xl font-bold '>{item.title}</h1>
              <p className='text-2xl'>{item.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
       {/* <HomeCard/> */}
    </div>
  );
}

export default Landing;
