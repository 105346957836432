import React from 'react';
import t from '../assets/team.webp'
// import client from '../assets/client.webp/'
const AboutUs = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="bg-zinc-900 text-white p-6 text-center">
        <h1 className="text-4xl font-bold">About Us</h1>
        <p className="mt-2">Learn more about our mission and values.</p>
      </header>

      <main className="flex-grow container mx-auto p-6">
        <section className="text-center mb-12">
          <h2 className="text-3xl font-semibold">Our Mission</h2>
          <p className="mt-4 text-gray-700">
            We strive to provide the best services and products to our customers while making a positive impact in our community.
          </p>
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white h-88  p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold">Who We Are</h3>
            <img src={t} alt="" />
            <p className="mt-4 text-gray-600">
              Our team is composed of dedicated professionals committed to excellence and innovation.
            </p>
          </div>

          <div className="bg-white  h-88 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold">What We Do</h3>
            <img src={t} alt="" />
            <p className="mt-4 text-gray-600">
              We offer a range of services designed to meet the needs of our clients and foster growth.
            </p>
          </div>
        </section>

        <section className="mt-12 text-center">
          <h2 className="text-3xl font-semibold">Our Values</h2>
          <ul className="mt-4 list-disc list-inside text-gray-700">
            <li>Integrity</li>
            <li>Innovation</li>
            <li>Customer Satisfaction</li>
            <li>Community Engagement</li>
          </ul>
        </section>
      </main>

      
    </div>
  );
};

export default AboutUs;
