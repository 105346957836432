import React from 'react'


function Buttons({label}) {
  return (
<div>
    <p className='font-medium capitalize'  type="text">{label}</p>
    </div>
  )
}

export default Buttons