import React from 'react'
// import Nav from './component/Nav';
import Routers from './config/router/Routers'




function App() {
  
  return (
    <div>
      <Routers/>
      
  </div>
  )
}

export default App