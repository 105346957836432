import React from 'react'
import {  Col, Row } from 'antd';


function Login() {
  return (
    <div>
        <div className="main">
          <Row className='flex  items-center justify-center p-4'>
            <Col md={18} >
<Row className=''>
  <Col md={14} xs={24} lg={14} className='border flex-col flex'>
  <img src="https://media.istockphoto.com/id/1312423107/vector/stealing-data-concept-flat-vector-illustration-online-registration-form-login-to-social.jpg?s=612x612&w=0&k=20&c=7Trftif8xV9FCDO5B4M7JiBpZUFlXo51m5lfI6hYCog=" alt="" />
  </Col>
  <Col md={10} xs={24} lg={10} className='border'>
  <div className="text-center">
    <h1 className='text-2xl font-semibold'>Welcome Back!</h1>
    <p>Login to your account</p>
  </div>
  <div className="  p-2 items-center gap-11 justify-start">
  <label  className='text-lg'> Email </label>
  <input className='p-3 w-full border-black border rounded' type="text" name="" id="" placeholder='@gmail.com'/>
  </div>  
  <div className="   p-2 items-center gap-3 justify-start">
  <label  className='text-lg'> Password </label>
  <input className='p-3 w-full border-black border rounded' type="password" name="" id="" placeholder='password'/>
  </div>  
  <div className=" flex  p-2 items-center gap-3 justify-between">
  <div className=" flex items-center justify-center gap-2">
  <input type="checkbox" name="" id="" />
  <p>remember me </p></div>
  <a href="#" className='text-blue-500'>Forget Password</a>
  </div>  
  <div className=" mt-12 ">
    <button className='bg-black text-white p-3 w-full '> Login</button>
  </div>
  
  </Col>
</Row>
            </Col>
          </Row>
        </div>

    </div>
  )
}

export default Login