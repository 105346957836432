import React from 'react';

const ServicePage = () => {
    const technologies = [
  {
    title: 'Python',
    image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2Fj0zoSD0e4V7siSTI221US9cMrtjc8A2og&s",
    description: 'Leverage the simplicity and efficiency of Python for back-end development, data analysis, and automation.',
  },
  {
    title: 'JavaScript',
    image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiXhjVqtRd6tiqcsSQ7rD6VF7NxI-3VvoR0w&s',
    description: 'Enhance interactivity and user experience with modern JavaScript frameworks and libraries.',
  },
  {
    title: 'Java',
    image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTTfSLvr6JNIBOy0OBG-ydUSOhjq7Y7KDRvg&s',
    description: 'Build robust and secure back-end systems with the reliability of Java.',
  },
  {
    title: 'PHP',
    image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyQwZSg5p0Gq6t5y9NIY9zlbUpTxI-vwvULQ&s',
    description: 'Utilize PHP for rapid development and dynamic web content.',
  },
  {
    title: 'Databases',
    image : 'https://media.licdn.com/dms/image/v2/D4D12AQHOe5HF849Xtw/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1696684905692?e=2147483647&v=beta&t=q15Mdy2Dk3motLTr21ucmpkzauWOP-vLUCPV55I5ihE',
    description: 'Choose from MySQL, PostgreSQL, MongoDB, and more for your data storage needs.',
  },
  {
    title: 'Tailwind CSS',
    image : 'https://picperf.io/https://laravelnews.s3.amazonaws.com/images/tailwindcss-1633184775.jpg',
    description: 'Create stunning, responsive designs with ease, enhancing your brand\'s online presence.',
  },
];

  return (
    <div className="bg-gray-100 text-center text-gray-800">
      {/* Header */}
      <header className="bg-zinc-900 text-white">
        <div className="container mx-auto px-6 py-4">
          <h1 className="text-3xl font-bold">Alpha X</h1>
          <p className="text-lg">Unlock the Power of Versatile Web Solutions</p>
        </div>
      </header>

      {/* Services Section */}
      <section className="container mx-auto my-10 p-6 text-center bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6">Our Technologies</h2>
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-6">
          {technologies.map((tech) => (
            <div key={tech.title} className="p-4 border flex flex-col items-center justify-center rounded-lg shadow hover:shadow-md transition">
              <h3 className="text-xl font-semibold">{tech.title}</h3>
              <img src={tech.image} alt="" />
              <p>{tech.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="container mx-auto my-10 p-6 text-center">
        <h2 className="text-2xl font-semibold mb-4">Get Started Today!</h2>
        <p className="mb-6">Transform your ideas into reality with our expert web development services.</p>
        <a href="mailto:contact@yourcompany.com" className="bg-zinc-900 text-white px-4 py-2 rounded hover:bg-zinc-700 transition">
          Contact Us
        </a>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white">
        <div className="container mx-auto px-6 py-4 text-center">
          <p>&copy; 2024 Your Company Name. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};


export default ServicePage;
