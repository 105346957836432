import React, { useState } from 'react'
import axios from 'axios';

function Card({label}) {
    const [product, setProduct] = useState([])
    // const [loader, setLoader] = useState(false)

    // const getProduct = ()=>{
    //     axios("https://fakestoreapi.com/products").then((res)=>
    //         {
    //             // console.log(res.data);
    //             setProduct([...res.data]);
                
    //         }).catch ((err)=>{
    //             // console.log(err);
                
    //         })
    // }
    // getProduct()
    // console.log(product);
    

  return (<>
     
            <div className="img w-40">
                <img className='w-full h-40 object-contain' src={label} alt="" />
            </div>
     
    </>
  )
}

export default Card