import React from 'react'
import { BrowserRouter,Link,Route,Routes } from 'react-router-dom'
import Home from '../../component/Home'
import Nav from '../../component/Nav'
import Footer from '../../component/Footer'
import AboutUs from '../../component/AboutUs'
import Services from '../../component/Services'
import NotFound from '../../component/NotFound'
import Login from '../../component/Login'
import ProjectPage from '../../component/Project'
import ContactUs from '../../component/ContactUs'
import AboutDemo from '../../component/AboutDemo'
// import Dashboard from '../../../pages/dashboard/Dashboard'

function Routers({rote}) {
  console.log(rote);
  
  return (
    <div>
        <BrowserRouter>
        <nav>
          <Link to={"/"} ><Nav/> </Link>
          {/* <Link to={"/about"}><Nav/></Link> */}
        </nav>
        <Routes>
            <Route path = "/" element ={<Home/>}/>
            <Route path = "aboutUs/:userName" element ={<AboutDemo/>}/>
            <Route path = "aboutUs" element ={<AboutUs/>}/>
            <Route path = "projects" element ={<ProjectPage/>}/>
            <Route path = "service" element ={<Services/>}/>
            <Route path = "contactUS" element ={<ContactUs/>}/>
            <Route path = "login" element ={<Login/>}/>
            {/* <Route path = "dashboard/*" element ={<Dashboard/>}/> */}
            <Route path = "*" element ={<NotFound/>}/>
        </Routes>
        <footer>
          <Link to={"/"} ><Footer/> </Link>
          {/* <Link to={"/about"}><Footer/></Link> */}
        </footer>
        </BrowserRouter>
    </div>
  )
}

export default Routers