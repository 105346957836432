import { useGSAP } from '@gsap/react';
import { Row, Col } from 'antd';
import gsap from 'gsap';
import React from 'react';

function HomeCards({ cards }) {
  const mainStyle = {
    clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
  };

  const overlayStyle = {
    width: '55%',
    height: '50%',
    position: 'absolute',
    clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
  };

  useGSAP(()=>{
    gsap.from(".box",{
        opacity:0,
        duration:5,
        y:-50,
        delay:3
        
    })
  })
  return (
    <div className='box'>
      <Row gutter={[16, 16]} justify="center">
        {cards.map((card, index) => (
          <Col key={index} md={8} sm={12} xs={24} className=''>
            <div className="card relative">
              <div
                style={mainStyle}
                className="hover:bg-black relative hover:text-white transition duration-1000 h-[80vh] mb-4 bg-[#F7F6F2]"
              >
                <div className="content  ms-[15%] w-[60%] absolute  bottom-0 flex items-center left-0 flex-col mb-[30%]">
                  <h1 className="text-4xl font-bold">{card.title}</h1>
                  <p className='text-center'>{card.des}</p>
                </div>
              </div>

              <div style={overlayStyle} className="bg-zinc-700 flex items-center justify-center absolute top-0">
                {card.image}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default HomeCards;
