import React from 'react'
import HomeContent from './HomeContent'
import Landing from './Landing'
import pic1 from '../assets/pic1.avif'
import pic2 from '../assets/pic2.avif'
import pic3 from '../assets/pic3.webp'
// import HomeCard from './HomeCards'

// import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
// import { GiHomeGarage } from "react-icons/gi";
// import { BsFillFileSpreadsheetFill } from "react-icons/bs";
import HomeCards from './HomeCards'
// import HomeCards from './HomeCards'

function Home() {
   const cardDate = [
    {
      title:"Core Planing",
       image: (
      <>
      {/* <HiOutlineBuildingOffice2 className='text-9xl text-white ' /> */}
      </>
    ),
      des:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat repudiandae similique"
    },
    {
      title:"Core Planing",
       image: (
      <>
      {/* <GiHomeGarage className='text-9xl text-white ' /> */}
      </>
    ),
      des:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat repudiandae similique"
    },
    {
      title:"Core Planing",
       image: (
      <>
     {/* <BsFillFileSpreadsheetFill className='text-9xl text-white ' /> */}
      </>
    ),
      des:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat repudiandae similique"
    },


   ]
   
  ;

    let carousel = [pic1,pic2,pic3]
  return (
    <div>
        <Landing images = {carousel}/>
      <HomeContent/>
      <HomeCards cards={cardDate}/>

     
    </div>
  )
}

export default Home