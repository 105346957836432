import React from 'react';

const projects = [
  {
    id: 1,
    title: 'Ecommerce',
    description: 'Description for project one goes here.',
    image: 'https://mir-s3-cdn-cf.behance.net/project_modules/hd/ce5d0c87104663.5dae0fd1e4dfe.jpg',
    link: '#'
  },
  {
    id: 2,
    title: 'Streaming App',
    description: 'Description for project two goes here.',
    image: 'https://restream.io/blog/content/images/2024/02/best-live-streaming-apps.jpg',
    link: '#'
  },
  {
    id: 3,
    title: 'Real estate',
    description: 'Description for project three goes here.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3abV5iJcMLwY3YnmAeaZX44hDuz0BQiXGXw&s',
    link: '#'
  }
];

const Projects = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <h1 className="text-center text-4xl font-bold mb-8">Our Projects</h1>
      <div className="flex justify-center flex-wrap gap-8">
        {projects.map((project) => (
          <div key={project.id} className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
            <img className="w-full h-48 object-cover" src={project.image} alt={project.title} />
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">{project.title}</h2>
              <p className="text-gray-600 mb-4">{project.description}</p>
              <a
                href={project.link}
                className="inline-block bg-zinc-900 text-white py-2 px-4 rounded hover:bg-zinc-700 transition"
              >
                View Project
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
