import React from 'react';

const ContactUs = () => {
  return (
    <div className="flex flex-col md:flex-row h-screen ">
      {/* Left Side - Contact Form */}
      <div className="flex flex-col justify-center border-r-4  border-zinc-900 p-8 bg-white w-full md:w-1/2">
        <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
        <form>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              rows="4"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-zinc-900 text-white font-semibold py-2 rounded hover:bg-zinc-700 transition duration-200"
          >
            Send Message
          </button>
        </form>
      </div>

      {/* Right Side - Company Logo */}
      <div className="flex items-center justify-center bg-white w-full md:w-1/2">
        <img className="w-full max-w-xs mix-blend-multiply " src="https://alpha-x.fr/wp-content/uploads/2023/09/alpha-x-logo.png" alt="Company Logo"  />
      </div>
    </div>
  );
};

export default ContactUs;
