import React from 'react'
import Card from './Card';
import BodyPic1 from '../assets/1.jpg' 
import BodyPic2 from '../assets/2.jpg' 
import BodyPic3 from '../assets/3.jpg' 
import server from '../assets/Retail.jpg' 
import cloud from '../assets/cloud.jpeg' 
import app from '../assets/application-development.avif' 
import web from '../assets/webdevelopmnt.jpg' 
import network from '../assets/network.jpg' 
import it from '../assets/IT-Support-Services-Detailed-IT-Services.jpg' 
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';



function HomeContent() {
  const navigate =  useNavigate()

  return (
    <div>
        <div className="body flex w-full h-full ">
          <Row className='flex items-center justify-center'>
            <Col xs={24} sm={24}  md={12} lg={12}>
            <div className="text-center  h-[70vh] flex flex-col justify-center items-center">
                <h1 className='text-5xl font-semibold p-2'>A MARK OF PRIDE</h1>
                <p className=' p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium aspernatur optio nisi assumenda voluptate repudiandae est tempora sunt, incidunt odit nostrum labore reprehenderit amet adipisci impedit, dolor vitae fugit voluptatibus.</p>
             <button className='bg-black text-white p-2  '>Read more</button>
            </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className='flex'>
            <div className=" ">
                <div className="images  flex flex-wrap items-center justify-center gap-4">
                <Card  label={web}/>
                <Card label={app} />
                <Card label={cloud} />
                <Card label={network}/>
                <Card label={server} />
                <Card label={it} />
                
                </div>
            </div>
               </Col>
            </Row>
        </div>
    </div>
  )
}

export default HomeContent